<template>
	<Popup name="etapes"
	text="<h3>La séparation</h3><p>s’effectue le plus souvent par distillation, c’est-à-dire en triant les hydrocarbures en fonction de leur facilité plus ou moins grande à passer de l’état liquide à l’état vapeur.</p><br/><h3>La transformation / conversion</h3><p>s’effectue sous l’action de la chaleur, de la pression et d’un catalyseur. Elle vise à transformer des molécules lourdes en molécules légères ou à améliorer la qualité du produit.</p><br/><h3>L’épuration/ amélioration</h3><p>permet d’éliminer les produits indésirables par des traitements chimiques spécifiques.</p><br/><h3>Le mélange</h3><p>consiste en une association de différentes bases afin d’obtenir les produits commerciaux (essences, bitumes, gazole…).</p>"
	video="static/video/raffinage.mp4"
	sticker="static/logo-total.jpg">
	<div class="extra__header" slot="extra__header">
		<div class="icon">
			<svg width="100%" height="100%" viewBox="0 0 623 623" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
    <g transform="matrix(1,0,0,1,-226.91,-3954.02)">
        <circle cx="538.17" cy="4265.28" r="311.26" style="fill:rgb(224,8,51);"/>
        <g>
            <g>
                <path d="M334,4231.52L334,4173.62L345.25,4173.62L345.25,4221.62L374.78,4221.62L374.78,4231.48L334,4231.52Z" style="fill:white;fill-rule:nonzero;"/>
                <path d="M398.71,4232.34C395.528,4232.38 392.369,4231.79 389.42,4230.59C384.094,4228.42 379.887,4224.15 377.79,4218.8C376.728,4216.13 376.188,4213.28 376.2,4210.4C376.183,4207.46 376.708,4204.55 377.75,4201.8C378.753,4199.15 380.28,4196.74 382.24,4194.7C384.263,4192.61 386.693,4190.96 389.38,4189.85C392.364,4188.61 395.57,4188 398.8,4188.05C402.001,4188 405.179,4188.61 408.13,4189.85C410.761,4190.97 413.142,4192.61 415.13,4194.66C417.057,4196.67 418.554,4199.05 419.53,4201.66C420.537,4204.32 421.048,4207.14 421.04,4209.98L421.04,4211.98C421.022,4212.53 420.955,4213.07 420.84,4213.61L387.84,4213.61C387.966,4215.17 388.38,4216.69 389.06,4218.1C390.291,4220.62 392.438,4222.57 395.06,4223.56C396.364,4224.05 397.747,4224.3 399.14,4224.3C401.295,4224.31 403.417,4223.76 405.3,4222.71C407.09,4221.83 408.509,4220.34 409.3,4218.51L418.68,4221.12C417.01,4224.47 414.386,4227.26 411.14,4229.12C407.378,4231.33 403.072,4232.45 398.71,4232.34ZM409.81,4206.57C409.662,4203.64 408.39,4200.88 406.26,4198.86C402.015,4194.93 395.375,4194.93 391.13,4198.86C390.13,4199.82 389.314,4200.95 388.72,4202.2C388.086,4203.56 387.712,4205.03 387.62,4206.53L409.81,4206.57Z" style="fill:white;fill-rule:nonzero;"/>
                <path d="M443.9,4232.34C440.324,4232.34 436.771,4231.77 433.38,4230.63C430.149,4229.59 427.155,4227.93 424.57,4225.73L428.65,4218.88C431.006,4220.59 433.566,4222 436.27,4223.08C438.621,4224.01 441.123,4224.49 443.65,4224.51C445.445,4224.61 447.229,4224.18 448.79,4223.29C449.994,4222.54 450.712,4221.2 450.67,4219.78C450.706,4218.32 449.821,4216.98 448.46,4216.44C446.141,4215.43 443.743,4214.61 441.29,4213.99C438.51,4213.23 436.15,4212.49 434.19,4211.79C432.504,4211.22 430.911,4210.41 429.46,4209.38C428.344,4208.58 427.446,4207.52 426.85,4206.28C426.28,4204.95 426.004,4203.52 426.04,4202.08C426.009,4200.05 426.439,4198.05 427.3,4196.21C428.133,4194.48 429.347,4192.96 430.85,4191.76C432.441,4190.5 434.251,4189.55 436.19,4188.95C438.341,4188.27 440.585,4187.94 442.84,4187.95C445.895,4187.93 448.934,4188.4 451.84,4189.34C454.689,4190.29 457.313,4191.82 459.55,4193.82L455.14,4200.43C453.216,4198.94 451.087,4197.73 448.82,4196.84C446.878,4196.1 444.819,4195.71 442.74,4195.7C441.118,4195.64 439.512,4196.04 438.1,4196.84C436.844,4197.62 436.119,4199.03 436.22,4200.51C436.194,4201.17 436.35,4201.82 436.67,4202.39C437.03,4202.94 437.522,4203.38 438.1,4203.69C438.916,4204.15 439.777,4204.51 440.67,4204.79C441.73,4205.15 443.04,4205.52 444.62,4205.9C447.56,4206.66 450.073,4207.42 452.16,4208.18C453.989,4208.8 455.719,4209.68 457.3,4210.79C458.537,4211.66 459.546,4212.82 460.24,4214.17C460.898,4215.62 461.219,4217.19 461.18,4218.78C461.333,4222.64 459.596,4226.34 456.53,4228.69C453.437,4231.12 449.227,4232.34 443.9,4232.34Z" style="fill:white;fill-rule:nonzero;"/>
                <path d="M507.67,4232.34C504.488,4232.38 501.329,4231.79 498.38,4230.59C493.054,4228.42 488.847,4224.15 486.75,4218.8C485.688,4216.13 485.148,4213.28 485.16,4210.4C485.143,4207.46 485.668,4204.55 486.71,4201.8C487.71,4199.15 489.238,4196.74 491.2,4194.7C493.221,4192.61 495.648,4190.96 498.33,4189.85C501.314,4188.61 504.52,4188 507.75,4188.05C510.954,4187.99 514.135,4188.61 517.09,4189.85C519.721,4190.97 522.102,4192.61 524.09,4194.66C526.017,4196.67 527.514,4199.05 528.49,4201.66C529.499,4204.32 530.011,4207.15 530,4210L530,4212C529.984,4212.55 529.914,4213.1 529.79,4213.63L496.79,4213.63C496.92,4215.19 497.337,4216.71 498.02,4218.12C499.251,4220.64 501.398,4222.59 504.02,4223.58C505.324,4224.07 506.707,4224.32 508.1,4224.32C510.255,4224.32 512.376,4223.78 514.26,4222.73C516.05,4221.85 517.469,4220.36 518.26,4218.53L527.64,4221.14C525.966,4224.49 523.343,4227.27 520.1,4229.14C516.336,4231.34 512.03,4232.45 507.67,4232.34ZM518.76,4206.57C518.62,4203.64 517.351,4200.88 515.22,4198.86C510.975,4194.93 504.335,4194.93 500.09,4198.86C499.09,4199.82 498.274,4200.95 497.68,4202.2C497.046,4203.56 496.672,4205.03 496.58,4206.53L518.76,4206.57ZM510.36,4183.81L503.36,4181.69L507.76,4171.99L518,4171.99L510.36,4183.81Z" style="fill:white;fill-rule:nonzero;"/>
                <path d="M562,4229.32C560.247,4230.09 558.451,4230.76 556.62,4231.32C554.452,4231.99 552.197,4232.32 549.93,4232.32C548.484,4232.33 547.043,4232.14 545.65,4231.75C544.361,4231.4 543.164,4230.77 542.14,4229.91C541.097,4229.02 540.273,4227.91 539.73,4226.65C539.099,4225.11 538.796,4223.46 538.84,4221.8L538.84,4197.25L533.2,4197.25L533.2,4188.85L538.83,4188.85L538.83,4174.98L549.76,4174.98L549.76,4188.85L558.76,4188.85L558.76,4197.25L549.76,4197.25L549.76,4218.13C549.634,4219.33 550.07,4220.52 550.94,4221.35C551.767,4221.99 552.788,4222.32 553.83,4222.29C554.971,4222.29 556.104,4222.1 557.18,4221.72C558.26,4221.34 559.18,4221.01 559.79,4220.72L562,4229.32Z" style="fill:white;fill-rule:nonzero;"/>
                <path d="M579.36,4232.34C577.385,4232.36 575.423,4232.02 573.57,4231.34C571.847,4230.7 570.266,4229.73 568.92,4228.48C567.628,4227.27 566.588,4225.81 565.86,4224.2C565.114,4222.51 564.739,4220.67 564.76,4218.82C564.745,4216.87 565.209,4214.95 566.11,4213.23C567.01,4211.51 568.293,4210.02 569.86,4208.87C571.601,4207.61 573.546,4206.66 575.61,4206.06C577.993,4205.36 580.467,4205.03 582.95,4205.06C584.824,4205.06 586.695,4205.22 588.54,4205.55C590.202,4205.83 591.829,4206.29 593.39,4206.93L593.39,4204.49C593.543,4202.07 592.667,4199.7 590.98,4197.96C589.38,4196.44 587.003,4195.68 583.85,4195.68C581.565,4195.68 579.298,4196.09 577.16,4196.9C574.791,4197.82 572.544,4199.02 570.47,4200.49L567.13,4193.56C572.372,4189.99 578.569,4188.09 584.91,4188.09C591.05,4188.09 595.82,4189.6 599.22,4192.62C602.62,4195.64 604.32,4200 604.32,4205.71L604.32,4219C604.24,4219.86 604.455,4220.73 604.93,4221.45C605.453,4221.99 606.179,4222.29 606.93,4222.27L606.93,4231.56C605.346,4231.91 603.732,4232.1 602.11,4232.13C600.408,4232.24 598.721,4231.75 597.34,4230.75C596.226,4229.82 595.486,4228.51 595.26,4227.08L595.02,4224.71C593.202,4227.14 590.825,4229.1 588.09,4230.42C585.364,4231.72 582.378,4232.37 579.36,4232.34ZM582.46,4224.34C584.261,4224.34 586.045,4224 587.72,4223.34C589.206,4222.8 590.536,4221.91 591.59,4220.73C592.652,4220.05 593.322,4218.89 593.39,4217.63L593.39,4212.74C592.011,4212.23 590.593,4211.83 589.15,4211.55C587.698,4211.26 586.221,4211.11 584.74,4211.1C582.286,4210.99 579.86,4211.67 577.81,4213.02C576.146,4214.07 575.13,4215.9 575.12,4217.87C575.089,4219.65 575.87,4221.35 577.24,4222.48C578.687,4223.73 580.549,4224.4 582.46,4224.35L582.46,4224.34Z" style="fill:white;fill-rule:nonzero;"/>
                <path d="M640.12,4232.34C637.034,4232.41 633.987,4231.64 631.31,4230.1C628.842,4228.64 626.814,4226.54 625.44,4224.02L625.44,4248.89L614.51,4248.89L614.51,4188.79L624.05,4188.79L624.05,4196.13C625.574,4193.69 627.676,4191.65 630.17,4190.21C632.808,4188.74 635.79,4187.99 638.81,4188.05C641.575,4188.02 644.311,4188.63 646.81,4189.81C649.212,4190.95 651.362,4192.56 653.13,4194.54C654.937,4196.6 656.347,4198.97 657.29,4201.54C658.3,4204.27 658.811,4207.15 658.8,4210.06C658.818,4213.03 658.349,4215.98 657.41,4218.79C656.555,4221.37 655.245,4223.77 653.54,4225.89C651.921,4227.86 649.904,4229.48 647.62,4230.62C645.29,4231.77 642.72,4232.36 640.12,4232.34ZM636.45,4223.04C638.028,4223.06 639.589,4222.71 641,4222C642.365,4221.31 643.571,4220.34 644.54,4219.15C645.541,4217.91 646.316,4216.5 646.83,4214.99C647.37,4213.41 647.644,4211.76 647.64,4210.09C647.651,4208.38 647.349,4206.69 646.75,4205.09C646.182,4203.61 645.336,4202.25 644.26,4201.09C643.203,4199.95 641.928,4199.04 640.51,4198.4C639.036,4197.74 637.437,4197.4 635.82,4197.4C634.739,4197.41 633.666,4197.59 632.64,4197.93C631.571,4198.28 630.56,4198.79 629.64,4199.44C628.713,4200.09 627.876,4200.85 627.15,4201.72C626.432,4202.57 625.867,4203.54 625.48,4204.58L625.48,4214.58C626.413,4216.97 627.958,4219.06 629.96,4220.66C631.79,4222.16 634.083,4222.99 636.45,4223L636.45,4223.04Z" style="fill:white;fill-rule:nonzero;"/>
                <path d="M686,4232.34C682.818,4232.38 679.659,4231.79 676.71,4230.59C671.388,4228.42 667.185,4224.15 665.09,4218.8C664.02,4216.13 663.476,4213.28 663.49,4210.4C663.473,4207.46 663.998,4204.55 665.04,4201.8C666.043,4199.15 667.57,4196.74 669.53,4194.7C671.555,4192.61 673.985,4190.96 676.67,4189.85C679.654,4188.61 682.86,4188 686.09,4188.05C689.291,4188 692.469,4188.61 695.42,4189.85C698.051,4190.97 700.432,4192.61 702.42,4194.66C704.35,4196.67 705.848,4199.05 706.82,4201.66C707.827,4204.32 708.338,4207.14 708.33,4209.98L708.33,4211.98C708.312,4212.53 708.245,4213.07 708.13,4213.61L675.13,4213.61C675.256,4215.17 675.67,4216.69 676.35,4218.1C677.581,4220.62 679.728,4222.57 682.35,4223.56C683.654,4224.05 685.037,4224.3 686.43,4224.3C688.585,4224.31 690.707,4223.76 692.59,4222.71C694.377,4221.83 695.795,4220.34 696.59,4218.51L705.96,4221.12C704.29,4224.47 701.666,4227.26 698.42,4229.12C694.661,4231.33 690.359,4232.44 686,4232.34ZM697.1,4206.57C696.952,4203.64 695.68,4200.88 693.55,4198.86C689.305,4194.93 682.665,4194.93 678.42,4198.86C677.42,4199.82 676.604,4200.95 676.01,4202.2C675.376,4203.56 675.002,4205.03 674.91,4206.53L697.1,4206.57Z" style="fill:white;fill-rule:nonzero;"/>
                <path d="M731.22,4232.34C727.644,4232.34 724.091,4231.77 720.7,4230.63C717.469,4229.59 714.475,4227.93 711.89,4225.73L715.97,4218.88C718.326,4220.59 720.886,4222 723.59,4223.08C725.941,4224.01 728.443,4224.49 730.97,4224.51C732.765,4224.61 734.549,4224.18 736.11,4223.29C737.314,4222.54 738.032,4221.2 737.99,4219.78C738.026,4218.32 737.141,4216.98 735.78,4216.44C733.461,4215.43 731.063,4214.61 728.61,4213.99C725.83,4213.23 723.47,4212.49 721.51,4211.79C719.824,4211.22 718.231,4210.41 716.78,4209.38C715.664,4208.58 714.766,4207.52 714.17,4206.28C713.6,4204.95 713.324,4203.52 713.36,4202.08C713.329,4200.05 713.759,4198.05 714.62,4196.21C715.456,4194.48 716.669,4192.96 718.17,4191.76C719.761,4190.5 721.571,4189.55 723.51,4188.95C725.661,4188.27 727.905,4187.94 730.16,4187.95C733.215,4187.93 736.254,4188.4 739.16,4189.34C742.009,4190.29 744.633,4191.82 746.87,4193.82L742.46,4200.43C740.536,4198.94 738.407,4197.73 736.14,4196.84C734.202,4196.1 732.145,4195.71 730.07,4195.7C728.445,4195.64 726.835,4196.04 725.42,4196.84C724.164,4197.62 723.439,4199.03 723.54,4200.51C723.514,4201.17 723.67,4201.82 723.99,4202.39C724.35,4202.94 724.842,4203.38 725.42,4203.69C726.237,4204.14 727.098,4204.51 727.99,4204.79C729.05,4205.15 730.36,4205.52 731.99,4205.9C734.93,4206.66 737.44,4207.42 739.54,4208.18C741.365,4208.8 743.092,4209.68 744.67,4210.79C745.907,4211.66 746.916,4212.82 747.61,4214.17C748.268,4215.62 748.589,4217.19 748.55,4218.78C748.703,4222.64 746.966,4226.34 743.9,4228.69C740.773,4231.12 736.547,4232.34 731.22,4232.34Z" style="fill:white;fill-rule:nonzero;"/>
            </g>
            <g>
                <path d="M650.57,4279.16C648.568,4279.18 646.586,4278.76 644.76,4277.94C643.009,4277.15 641.445,4276.01 640.17,4274.57C638.87,4273.1 637.852,4271.41 637.17,4269.57C636.447,4267.59 636.085,4265.49 636.1,4263.38C636.075,4261.29 636.413,4259.21 637.1,4257.23C637.728,4255.41 638.68,4253.71 639.91,4252.23C641.092,4250.82 642.557,4249.67 644.21,4248.86C645.913,4248.04 647.781,4247.62 649.67,4247.64C651.805,4247.61 653.906,4248.18 655.74,4249.27C657.484,4250.3 658.934,4251.76 659.95,4253.51L659.95,4236.2L667.73,4236.2L667.73,4269.66C667.679,4270.27 667.823,4270.88 668.14,4271.4C668.497,4271.79 669.005,4272 669.53,4271.98L669.53,4278.6C668.442,4278.84 667.334,4278.98 666.22,4279.01C664.994,4279.06 663.786,4278.69 662.8,4277.96C661.929,4277.31 661.378,4276.31 661.29,4275.23L661.11,4273.32C660.052,4275.19 658.466,4276.72 656.55,4277.7C654.704,4278.66 652.652,4279.16 650.57,4279.16ZM652.57,4272.54C653.342,4272.54 654.109,4272.41 654.84,4272.16C655.606,4271.91 656.33,4271.55 656.99,4271.09C657.647,4270.62 658.242,4270.08 658.76,4269.46C659.27,4268.87 659.672,4268.19 659.95,4267.46L659.95,4260.2C659.645,4259.35 659.203,4258.56 658.64,4257.85C658.093,4257.14 657.45,4256.52 656.73,4255.99C656.033,4255.47 655.269,4255.05 654.46,4254.74C653.713,4254.44 652.916,4254.28 652.11,4254.27C650.991,4254.26 649.887,4254.52 648.89,4255.03C647.908,4255.53 647.045,4256.23 646.36,4257.09C645.648,4257.99 645.096,4259 644.73,4260.09C644.347,4261.21 644.151,4262.39 644.15,4263.58C644.141,4264.79 644.358,4265.99 644.79,4267.12C645.196,4268.17 645.785,4269.13 646.53,4269.97C647.271,4270.78 648.174,4271.43 649.18,4271.88C650.258,4272.35 651.425,4272.57 652.6,4272.54L652.57,4272.54Z" style="fill:white;fill-rule:nonzero;"/>
                <path d="M684.72,4279.16C681.59,4279.16 679.21,4278.16 677.58,4276.16C675.95,4274.16 675.14,4271.16 675.14,4267.22L675.14,4248.17L682.92,4248.17L682.92,4265.53C682.92,4270.2 684.607,4272.53 687.98,4272.53C689.541,4272.51 691.064,4272.04 692.36,4271.17C693.879,4270.13 695.078,4268.69 695.82,4267.01L695.82,4248.14L703.6,4248.14L703.6,4269.63C703.547,4270.24 703.702,4270.86 704.04,4271.37C704.411,4271.75 704.926,4271.97 705.46,4271.95L705.46,4278.57C704.8,4278.71 704.132,4278.81 703.46,4278.86C702.94,4278.86 702.46,4278.92 702.04,4278.92C700.829,4278.96 699.635,4278.61 698.64,4277.92C697.78,4277.3 697.221,4276.34 697.1,4275.28L696.93,4272.84C695.652,4274.86 693.842,4276.48 691.7,4277.54C689.53,4278.61 687.14,4279.17 684.72,4279.16Z" style="fill:white;fill-rule:nonzero;"/>
            </g>
            <g>
                <path d="M341.82,4305.53C337.477,4305.53 333.184,4306.45 329.23,4308.25C325.708,4309.79 322.863,4312.56 321.23,4316.04L321.23,4352.66L305.78,4352.66L305.78,4292.13L320,4292.13L320,4305.07C321.028,4303.05 322.308,4301.17 323.81,4299.47C325.222,4297.86 326.809,4296.4 328.54,4295.14C330.119,4293.97 331.848,4293.02 333.68,4292.31C335.272,4291.68 336.967,4291.34 338.68,4291.31L340.58,4291.31C340.99,4291.31 341.398,4291.35 341.8,4291.43L341.82,4305.53Z" style="fill:white;fill-rule:nonzero;"/>
                <path d="M366.19,4353.81C363.392,4353.83 360.613,4353.35 357.99,4352.37C355.552,4351.48 353.313,4350.12 351.4,4348.37C349.57,4346.65 348.098,4344.59 347.07,4342.3C346.012,4339.9 345.48,4337.3 345.51,4334.68C345.488,4331.93 346.144,4329.21 347.42,4326.77C348.696,4324.33 350.513,4322.22 352.73,4320.59C355.195,4318.8 357.953,4317.44 360.88,4316.59C364.253,4315.6 367.755,4315.11 371.27,4315.15C373.922,4315.15 376.569,4315.38 379.18,4315.84C381.541,4316.25 383.848,4316.92 386.06,4317.84L386.06,4314.37C386.06,4310.37 384.923,4307.29 382.65,4305.13C380.377,4302.97 377.007,4301.89 372.54,4301.9C369.305,4301.9 366.095,4302.48 363.07,4303.63C359.717,4304.93 356.536,4306.63 353.6,4308.71L348.86,4298.89C356.284,4293.84 365.061,4291.14 374.04,4291.15C382.74,4291.15 389.497,4293.29 394.31,4297.56C399.123,4301.83 401.53,4308.01 401.53,4316.1L401.53,4334.93C401.419,4336.15 401.726,4337.37 402.4,4338.39C403.138,4339.16 404.166,4339.58 405.23,4339.55L405.23,4352.72C402.989,4353.22 400.705,4353.49 398.41,4353.53C395.49,4353.53 393.23,4352.87 391.66,4351.53C390.058,4350.22 388.991,4348.37 388.66,4346.33L388.32,4342.99C385.741,4346.43 382.373,4349.2 378.5,4351.07C374.657,4352.9 370.447,4353.84 366.19,4353.81ZM370.58,4342.49C373.128,4342.5 375.655,4342.03 378.03,4341.11C380.134,4340.35 382.017,4339.08 383.51,4337.41C385.017,4336.44 385.968,4334.81 386.06,4333.02L386.06,4326.09C384.109,4325.37 382.102,4324.81 380.06,4324.42C378.006,4324 375.916,4323.79 373.82,4323.78C370.343,4323.63 366.905,4324.58 364,4326.5C361.64,4327.98 360.2,4330.58 360.19,4333.37C360.14,4335.89 361.246,4338.29 363.19,4339.89C365.243,4341.65 367.88,4342.57 370.58,4342.49Z" style="fill:white;fill-rule:nonzero;"/>
                <path d="M419.2,4307.15L411.2,4307.15L411.2,4295.37L419.2,4295.37L419.2,4293.87C419.184,4290.91 419.654,4287.96 420.59,4285.15C421.454,4282.53 422.812,4280.09 424.59,4277.98C426.368,4275.91 428.576,4274.26 431.06,4273.13C433.801,4271.9 436.778,4271.29 439.78,4271.34C442.368,4271.34 444.947,4271.65 447.46,4272.27C449.931,4272.87 452.347,4273.68 454.68,4274.69C456.9,4272.28 459.635,4270.41 462.68,4269.21C466.284,4267.8 470.132,4267.12 474,4267.21C477.982,4267.21 481.933,4267.91 485.67,4269.29C489.477,4270.7 493.119,4272.52 496.53,4274.72L490.4,4286.27C488.32,4284.48 485.954,4283.06 483.4,4282.06C480.895,4280.99 478.204,4280.42 475.48,4280.38C471.79,4280.38 469.11,4281.46 467.48,4283.62C465.827,4285.77 464.949,4288.41 464.99,4291.12L464.99,4295.4L497.57,4295.4L497.57,4352.69L481.97,4352.69L481.97,4307.18L464.97,4307.18L464.97,4352.69L449.47,4352.69L449.47,4307.18L434.68,4307.18L434.68,4352.69L419.2,4352.69L419.2,4307.15ZM449.47,4295.37L449.47,4293.46C449.47,4292.55 449.57,4291.65 449.65,4290.69C449.721,4289.79 449.838,4288.89 450,4288C450.102,4287.38 450.256,4286.76 450.46,4286.16C449.254,4285.52 447.949,4285.09 446.6,4284.89C445.358,4284.67 444.101,4284.55 442.84,4284.54C439.91,4284.54 437.84,4285.41 436.55,4287.14C435.247,4288.97 434.576,4291.18 434.64,4293.43L434.64,4295.43L449.47,4295.37Z" style="fill:white;fill-rule:nonzero;"/>
                <path d="M567.74,4352.66L552.26,4352.66L552.26,4318.66C552.26,4313.81 551.413,4310.27 549.72,4308.03C548.081,4305.83 545.46,4304.58 542.72,4304.68C541.096,4304.69 539.489,4305.01 537.98,4305.61C536.335,4306.23 534.796,4307.11 533.42,4308.21C531.955,4309.36 530.653,4310.71 529.55,4312.21C528.4,4313.73 527.54,4315.46 527.01,4317.29L527.01,4352.64L511.49,4352.64L511.49,4292.11L525.49,4292.11L525.49,4303.32C527.764,4299.43 531.14,4296.3 535.19,4294.32C539.635,4292.11 544.547,4291 549.51,4291.08C552.719,4290.95 555.906,4291.67 558.75,4293.16C561.042,4294.45 562.956,4296.33 564.3,4298.59C565.646,4300.95 566.564,4303.53 567.01,4306.21C567.511,4309.07 567.762,4311.97 567.76,4314.88L567.74,4352.66Z" style="fill:white;fill-rule:nonzero;"/>
                <path d="M597.77,4353.81C594.972,4353.83 592.193,4353.35 589.57,4352.37C587.134,4351.48 584.898,4350.13 582.99,4348.37C581.155,4346.65 579.68,4344.59 578.65,4342.3C577.595,4339.9 577.066,4337.3 577.1,4334.68C577.075,4331.93 577.727,4329.21 579,4326.77C580.276,4324.33 582.093,4322.22 584.31,4320.59C586.775,4318.8 589.533,4317.44 592.46,4316.59C595.833,4315.6 599.335,4315.11 602.85,4315.15C605.502,4315.15 608.149,4315.38 610.76,4315.84C613.121,4316.25 615.428,4316.92 617.64,4317.84L617.64,4314.37C617.64,4310.37 616.503,4307.29 614.23,4305.13C611.957,4302.97 608.587,4301.89 604.12,4301.9C600.885,4301.9 597.675,4302.48 594.65,4303.63C591.297,4304.93 588.116,4306.63 585.18,4308.71L580.44,4298.89C587.865,4293.84 596.641,4291.14 605.62,4291.15C614.333,4291.15 621.093,4293.29 625.9,4297.56C630.707,4301.83 633.11,4308.01 633.11,4316.1L633.11,4334.93C632.999,4336.15 633.306,4337.37 633.98,4338.39C634.718,4339.16 635.746,4339.58 636.81,4339.55L636.81,4352.72C634.573,4353.22 632.291,4353.49 630,4353.53C627.073,4353.53 624.82,4352.86 623.24,4351.53C621.638,4350.22 620.571,4348.37 620.24,4346.33L620,4343C617.421,4346.44 614.053,4349.21 610.18,4351.08C606.305,4352.92 602.06,4353.86 597.77,4353.81ZM602.16,4342.49C604.708,4342.5 607.236,4342.03 609.61,4341.11C611.717,4340.35 613.603,4339.08 615.1,4337.41C616.601,4336.44 617.547,4334.81 617.64,4333.02L617.64,4326.09C615.689,4325.37 613.682,4324.81 611.64,4324.42C609.586,4324 607.496,4323.79 605.4,4323.78C601.926,4323.63 598.491,4324.58 595.59,4326.5C593.225,4327.98 591.78,4330.58 591.77,4333.37C591.72,4335.89 592.826,4338.29 594.77,4339.89C596.823,4341.65 599.46,4342.57 602.16,4342.49Z" style="fill:white;fill-rule:nonzero;"/>
                <path d="M671.23,4353C667.396,4353.06 663.601,4352.23 660.14,4350.58C656.85,4348.98 653.922,4346.72 651.54,4343.94C649.075,4341.07 647.172,4337.76 645.93,4334.18C643.284,4326.39 643.326,4317.94 646.05,4310.18C647.358,4306.55 649.313,4303.18 651.82,4300.25C656.914,4294.36 664.363,4291.01 672.15,4291.12C676.484,4291.02 680.761,4292.13 684.51,4294.3C688.05,4296.42 691.054,4299.32 693.29,4302.79L693.29,4292.16L706.8,4292.16L706.8,4349.8C706.87,4353.94 706.002,4358.04 704.26,4361.8C702.613,4365.27 700.223,4368.35 697.26,4370.8C694.116,4373.36 690.506,4375.28 686.63,4376.46C682.273,4377.82 677.733,4378.49 673.17,4378.46C666.55,4378.46 660.987,4377.36 656.48,4375.16C651.968,4372.96 648.002,4369.78 644.87,4365.86L653.31,4357.66C655.654,4360.54 658.641,4362.84 662.03,4364.36C665.52,4365.98 669.324,4366.81 673.17,4366.79C675.5,4366.79 677.817,4366.45 680.05,4365.79C682.176,4365.16 684.157,4364.12 685.88,4362.73C687.614,4361.3 688.985,4359.47 689.88,4357.41C690.899,4355 691.394,4352.4 691.33,4349.79L691.33,4342.17C689.351,4345.59 686.422,4348.37 682.9,4350.17C679.3,4352.05 675.293,4353.02 671.23,4353ZM676.43,4340.64C678.08,4340.65 679.72,4340.38 681.28,4339.84C684.31,4338.77 686.988,4336.9 689.02,4334.41C689.991,4333.23 690.771,4331.9 691.33,4330.48L691.33,4316C690.031,4312.58 687.761,4309.61 684.8,4307.46C682.133,4305.4 678.869,4304.26 675.5,4304.22C673.247,4304.19 671.021,4304.73 669.03,4305.78C667.098,4306.81 665.397,4308.23 664.03,4309.94C662.611,4311.73 661.533,4313.76 660.85,4315.94C660.117,4318.18 659.746,4320.52 659.75,4322.87C659.738,4325.24 660.168,4327.59 661.02,4329.8C661.834,4331.89 663.031,4333.81 664.55,4335.46C666.035,4337.06 667.819,4338.35 669.8,4339.27C671.883,4340.21 674.146,4340.67 676.43,4340.64Z" style="fill:white;fill-rule:nonzero;"/>
                <path d="M748.85,4353.81C744.338,4353.87 739.86,4353.03 735.68,4351.33C728.141,4348.26 722.186,4342.22 719.22,4334.64C717.717,4330.85 716.953,4326.81 716.97,4322.74C716.943,4318.58 717.685,4314.45 719.16,4310.56C720.574,4306.82 722.735,4303.4 725.51,4300.51C728.38,4297.56 731.82,4295.22 735.62,4293.63C739.848,4291.89 744.387,4291.02 748.96,4291.09C753.498,4291.02 758.003,4291.88 762.19,4293.63C765.92,4295.22 769.296,4297.54 772.12,4300.45C774.852,4303.3 776.975,4306.68 778.36,4310.38C779.778,4314.15 780.5,4318.14 780.49,4322.16L780.49,4325.05C780.468,4325.83 780.371,4326.6 780.2,4327.36L733.37,4327.36C733.55,4329.57 734.137,4331.72 735.1,4333.71C735.984,4335.5 737.199,4337.11 738.68,4338.45C740.142,4339.76 741.838,4340.78 743.68,4341.45C745.526,4342.13 747.482,4342.47 749.45,4342.45C752.501,4342.46 755.504,4341.68 758.17,4340.2C760.685,4338.94 762.673,4336.83 763.78,4334.25L777,4338C774.635,4342.76 770.919,4346.72 766.32,4349.38C761.017,4352.44 754.97,4353.97 748.85,4353.81ZM764.56,4317.31C764.36,4313.17 762.568,4309.26 759.56,4306.4C753.535,4300.84 744.124,4300.86 738.13,4306.46C736.714,4307.81 735.561,4309.42 734.73,4311.19C733.822,4313.11 733.292,4315.19 733.17,4317.31L764.56,4317.31Z" style="fill:white;fill-rule:nonzero;"/>
            </g>
        </g>
    </g>
</svg>
		</div>
		<div class="text__big">Explication du processus de raffinage</div>
	</div>
	<picture slot="thumbnail__1">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/shema_produits_fini_GRN_vl8efl_c_scale_w_350.jpg 350w,
		img/popup/shema_produits_fini_GRN_vl8efl_c_scale_w_700.jpg 700w,
		img/popup/shema_produits_fini_GRN_vl8efl_c_scale_w_970.jpg 970w"
		data-src="img/popup/shema_produits_fini_GRN_vl8efl_c_scale_w_970.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</Popup>
</template>

<script>
	export default {
		components: {
			Popup: () => import('@/components/Popup.vue'),
		},
	}
</script>
